import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";
import CharityIcon from "../assets/charity-icon.svg";
import { useStaticQuery, graphql } from "gatsby";
import MaxWidthSection from "../components/Layout/MaxWidthSection";
import CharityWithImageSection from "../components/CharityWithImageSection";

const CharityStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .top-statement {
    grid-column: 1 / span 12;
    display: flex;
    justify-content: space-between;
    background-color: var(--color-very-light-gray);
    margin-bottom: 2em;
    padding: 3em;

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1.2em;
    }

    .charity-icon {
      min-width: 6em;
      max-width: 6em;
      min-height: 6em;
      max-height: 6em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-accent);
      background: linear-gradient(
        90deg,
        var(--color-accent-light) 0%,
        var(--color-accent) 100%
      );
      border-radius: 50%;
      margin-right: 2em;
      @media ${(props) => props.theme.breakpoints.m} {
        margin-right: 0;
        margin-bottom: 1em;
      }

      img {
        display: block;
        width: 3em;
      }
    }
  }
`;

const Charity = () => {
  const data = useStaticQuery(graphql`
    query CharityPageQuery {
      sanityCharityPage {
        title
        content
        charities {
          name
          description
          charityImage {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          charityLogo {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 250
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          imageLeft
          grayBg
        }
      }
    }
  `);

  const { title, content } = data.sanityCharityPage;
  const charities = data.sanityCharityPage.charities;

  return (
    <Layout>
      <Seo title="Charity" />
      <TopTitleBar title={title} />
      <CharityStyles>
        <MaxWidthSection>
          <div className="top-statement">
            <div className="charity-icon">
              <img src={CharityIcon} alt="charity" className="icon" />
            </div>
            <div className="statement">
              <p>{content}</p>
            </div>
          </div>
        </MaxWidthSection>
        {charities.map((charity, i) => (
          <CharityWithImageSection
            key={i}
            charityLogo={
              charity.charityLogo.asset.localFile.childImageSharp
                .gatsbyImageData
            }
            charityContent={charity.description}
            charityImage={
              charity.charityImage.asset.localFile.childImageSharp
                .gatsbyImageData
            }
            imageLeft={charity.imageLeft}
            charityName={charity.name}
            grayBg={charity.grayBg}
          />
        ))}
      </CharityStyles>
    </Layout>
  );
};

export default Charity;
