import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import MaxWidthSection from "./Layout/MaxWidthSection";

const CharityWithImageSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;
  &:last-child {
    padding-bottom: 0;
  }
  background: ${(props) =>
    props.grayBg ? "var(--color-very-light-gray);" : "transparent;"};

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .charity-details {
    grid-column: ${(props) =>
      props.imageLeft ? "7 / span 6;" : "1 / span 6;"};
    grid-row: 1/1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: ${(props) => (props.imageLeft ? "left;" : "right;")};
    p {
      text-align: justify;
    }

    .logo {
      margin-bottom: 1em;
    }

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      grid-row: 2;
      text-align: center;
      align-items: center;
    }
  }

  .charity-image {
    grid-column: ${(props) =>
      props.imageLeft ? "1 / span 6;" : "7 / span 6;"};
    grid-row: 1/1;

    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      margin-bottom: 2em;
    }
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`;

const CharityWithImageSection = ({
  charityLogo,
  charityContent,
  charityImage,
  imageLeft,
  charityName,
  grayBg,
}) => {
  return (
    <CharityWithImageSectionStyles imageLeft={imageLeft} grayBg={grayBg}>
      <MaxWidthSection>
        <div className="charity-details">
          <GatsbyImage
            image={charityLogo}
            alt={`${charityName} Logo`}
            className="logo"
          />
          <p>{charityContent}</p>
        </div>
        <div className="charity-image">
          <GatsbyImage image={charityImage} alt={charityName} />
        </div>
      </MaxWidthSection>
    </CharityWithImageSectionStyles>
  );
};

export default CharityWithImageSection;
